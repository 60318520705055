import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  IonSpinner,
  setupIonicReact,
  useIonViewDidEnter,
 } from '@ionic/react';
 
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

import Menu from './components/Menu';
import Page from './pages/Page';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Reset from './pages/Reset';

import Success from './components/containers/Success';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { app, auth, user, getCurrentUser, readUser } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { setUserState } from './redux/actions';
import { useDispatch } from 'react-redux';
import AdminReceivingNumbers from './components/containers/AdminReceivingNumbers';
import Admin from './components/containers/Admin';
import Integrations from './components/containers/Integrations';
import CTM from './components/containers/CTM';

setupIonicReact();


//TODO  - Eventually want to expire login cookie after 10-15 min for security reasons. Mostly just if we decide to include PHI
const RoutingSystemAdmin: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Integrations />
            </Route>
            <Route path="/Integrations" exact={true}>
              <Integrations />
            </Route>
            <Route path="/Integrations/CTM" exact={true}>
              <CTM />
            </Route>
            <Route path="/Admin" exact={true}>
              <Admin />
            </Route>
            <Route path="/Admin/Receiving-Numbers" exact={true}>
              <AdminReceivingNumbers />
            </Route>
            <Route path="/api/callback" exact={true}>
              <Success />
            </Route> 
            <Route path="/api/bigben" exact={true}>
            </Route> 
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};


const RoutingSystemLoggedIn: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Integrations />
            </Route>
            <Route path="/Integrations" exact={true}>
              <Integrations />
            </Route>
            <Route path="/Integrations/CTM" exact={true}>
              <CTM />
            </Route>
            <Route path="/api/callback" exact={true}>
              <Success />
            </Route>
            <Route path="/api/bigben" exact={true}>
            </Route> 
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

const RoutingSystemLoggedOut: React.FC = () => {

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <IonRouterOutlet id="main">
            <Route>
              <Redirect to="/Login"/>
            </Route>
            <Route path="/Login" exact={true}>
              <Login />
            </Route>
            <Route path="/Signup" exact={true}>
              <Signup />
            </Route>
            <Route path="/Reset" exact={true}>
              <Reset />
            </Route>
            <Route path="/api/bigben" exact={true}>
            </Route> 
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

const App: React.FC = () => {

  const [ loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); 
  const [ busy, setBusy] = useState(true);
  const dispatch = useDispatch();

  useEffect(()=>{
    getCurrentUser().then( (user: any) => {
      if (user){
        console.log("User Logged In"); //todo - remove debugging
        console.log(user); //todo - remove debugging
        dispatch(setUserState(user));
        
        readUser(user.uid).then((data: any) => {
          console.log(data);

          if (data && data.restricted && data.restricted.role && data.restricted.role == "admin"){
            console.log("Is Admin!"); //todo - remove debugging
            setIsAdmin(true);
            setLoggedIn(true);
            setBusy(false);
          }
          else {
            console.log("Not Admin!"); //todo - remove debugging
            setIsAdmin(false);
            setLoggedIn(true);
            setBusy(false);
          }
        }); 
      }
      else {
        console.log("No User Logged In"); //todo - remove debugging
        setLoggedIn(false);
        setBusy(false);
      }
    }).catch(e => {
      console.log(e);
      setLoggedIn(false);
      setBusy(false);
    });
  });

  return (
    <IonApp>
      {busy ? <IonSpinner /> : loggedIn ? isAdmin ? <RoutingSystemAdmin /> : <RoutingSystemLoggedIn /> : <RoutingSystemLoggedOut />}
    </IonApp>
  );
};

export default App;
