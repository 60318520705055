export const setUserState = (payload: any) => {
  return {type: 'SET_USER_STATE', payload };
}

export const setRedirectURIState = (payload: any) => {
  return {type: 'SET_REDIRECT_URI_STATE', payload };
}

export const setAccessTokenState = (payload: any) => {
  return {type: 'SET_ACCESS_TOKEN_STATE', payload };
}

export const setRefreshTokenState = (payload: any) => {
  return {type: 'SET_REFRESH_TOKEN_STATE', payload };
}
