import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonToast,
  IonIcon,
  IonLoading,
  IonImg
} from '@ionic/react';

import {
  alertCircleSharp,
  refresh
} from 'ionicons/icons';

import './Login.css';
import { useEffect, useState } from 'react';
import { setUserState, setAccessTokenState, setRedirectURIState, setRefreshTokenState } from '../redux/actions';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useDispatch } from 'react-redux';

import { app, auth, createRefreshToken, writeBasicUserInfo, readUser } from '../firebase';
import { useLocation } from 'react-router';

const Login: React.FC = () => {

  const location = useLocation();

  var partnerCode = new URLSearchParams(location.search).get("partner");

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showToast_error, setShowToast_error] = useState(false);
  const [showToast_passwordReset, setShowToast_passwordReset] = useState(false);
  const [isOauth, setIsOauth] = useState(false);
  const [partner, setPartner] = useState("");
  const [busy, setBusy] = useState(false);

  const dispatch = useDispatch();

  // URL Example: https://app.rolloverrep.com/Login?redirect_uri=https://ctm.com 
  var redirectUri = new URLSearchParams(location.search).get("redirect_uri");

  function loginUser(){
    setBusy(true);

    signInWithEmailAndPassword(auth, username, password).then((userCredential) => {
      const user = userCredential.user;
      dispatch(setUserState(user));

      writeBasicUserInfo(user.uid, username).then(async () => {

        if (redirectUri){

          var accessToken:any = await user.getIdToken(); 
          //dispatch(setAccessTokenState(accessToken));
          //dispatch(setRedirectURIState(redirectUri));

          var refreshToken: any = ""; 

          var userInfo: any = await readUser(user.uid); 

          if (userInfo.private && userInfo.private.refresh_token){
            refreshToken = userInfo.private.refresh_token; 
            console.log("Refresh Token: "+ refreshToken); //todo - remove debugging
          }
          else {
            refreshToken = await createRefreshToken(user.uid);
          }

          const encodedRedirectUri = encodeURIComponent(redirectUri);
          const encodedAccessToken = encodeURIComponent(accessToken);
          const encodedRefreshToken = encodeURIComponent(refreshToken);

          //dispatch(setRefreshTokenState(refreshToken));

          console.log("Access Token: "+ accessToken); //todo - remove debugging


          /*if (redirectUri.includes("?")){
            redirectUri = redirectUri + "&access_token=" + accessToken+"&refresh_token=" + refreshToken;
          }
          else {
            redirectUri = redirectUri + "?access_token=" + accessToken+"&refresh_token=" + refreshToken;
          }*/ 

          console.log("Redirecting to: "+ redirectUri); //todo - remove debugging

          //window.history.replaceState({}, '', redirectUri); 

          switch(partner){
            case "CTM":
              window.location.href = `/Integrations/CTM?redirect_uri=${encodedRedirectUri}&access_token=${encodedAccessToken}&refresh_token=${encodedRefreshToken}`;
              break;
            default:
              window.location.href = "/Integrations";
              break;
          }
         // window.location.href = redirectUri;
        }
        else {
          window.history.replaceState({}, '', '/Integrations');
          window.location.href = "/Integrations";
        }
      }).catch((error:any) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setShowToast_error(true);
        console.log(errorCode, errorMessage);
      });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      setShowToast_error(true);
      console.log(errorCode, errorMessage);
    });
    setBusy(false);
  }

  useEffect(() => { 

    if (redirectUri){
      setIsOauth(true); 
    }

    if (partnerCode){
      switch(partnerCode){ 
        case "ctm": 
          setPartner("CTM");
          break;
        default: 
          break; 
      }
    }

  }, []); 

  return (
    <IonPage>
      <IonContent fullscreen className="login-background">
        <div className="login-background-overlay">
          <div className="login-area">
            <IonImg className="rollover-rep-logo-login" src="https://i.imgur.com/akmZhdi.png" />
            <IonItem>
              <IonInput
                placeholder="Email"
                onIonChange={(event: any) => setUsername(event.target.value)}
              />
            </IonItem>
            <IonItem>
              <IonInput
                placeholder="Password"
                onIonChange={(event: any) => setPassword(event.target.value)}
                type="password"
              />
            </IonItem>
            <IonButton color="primary" expand="full" onClick={loginUser}>
              Login
            </IonButton>
            <p className="sign-up-text"> Don't have an account? <a href={(isOauth ? "https://app.usemotion.com/meet/rollover-rep-tyler/ctm" : "/Signup")}> Sign up. </a></p>
            <p className="sign-up-text"> Need help logging in? <a href="/Reset"> Reset your password.</a></p>
          </div>
        </div>
        <IonToast
          isOpen={showToast_error}
          onDidDismiss={() => setShowToast_error(false)}
          message="Invalid Username or Password"
          duration={2000}
          color="danger"
          icon={alertCircleSharp}
        />
        <IonToast
          isOpen={showToast_passwordReset}
          onDidDismiss={() => setShowToast_passwordReset(false)}
          message="Invalid Username or Password"
          duration={2000}
          color="danger"
          icon={alertCircleSharp}
        />

        <IonLoading message="Please wait..." duration={0} isOpen={busy} />
      </IonContent>
    </IonPage>
  );
};

export default Login;
