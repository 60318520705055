const defaultState = {
  userData: null,
  redirectUri: null,
  accessToken: null,
  refreshToken: null
};

export default function reducer(state = defaultState, {type, payload}: {type: string; payload: any}): any{
  //work with state
  switch (type){
    case 'SET_USER_STATE':
      return {
        ...state,
        userData: payload
      }
    case 'SET_REDIRECT_URI_STATE':
      return {
        ...state,
        redirectUri: payload
      }
    case 'SET_ACCESS_TOKEN_STATE':
      return {
        ...state,
        accessToken: payload
      }
    case 'SET_REFRESH_TOKEN_STATE':
      return {
        ...state,
        refreshToken: payload
      }
  }

  return state;
}
