import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonImg
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, homeOutline, homeSharp, globeOutline, globeSharp, settingsOutline, settingsSharp, logOutOutline } from 'ionicons/icons';
import './Menu.css';

import { useEffect, useState } from 'react';
import { getCurrentUser, readUser } from '../firebase';

import { logoutUser } from '../firebase';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  /*{
    title: 'Home',
    url: '/page/Home',
    iosIcon: homeOutline,
    mdIcon: homeSharp
  },
  {
    title: 'Inbox',
    url: '/page/Inbox',
    iosIcon: paperPlaneOutline,
    mdIcon: paperPlaneSharp
  },*/
  {
    title: 'Integrations',
    url: '/Integrations',
    iosIcon: globeOutline,
    mdIcon: globeSharp
  }/*,
  {
    title: 'Settings',
    url: '/page/Settings',
    iosIcon: settingsOutline,
    mdIcon: settingsSharp
  },
  {
    title: 'Trash',
    url: '/page/Trash',
    iosIcon: trashOutline,
    mdIcon: trashSharp
  }*/
];

const appPagesAdmin: AppPage[] = [
  /*{
    title: 'Home',
    url: '/page/Home',
    iosIcon: homeOutline,
    mdIcon: homeSharp
  },
  {
    title: 'Inbox',
    url: '/page/Inbox',
    iosIcon: paperPlaneOutline,
    mdIcon: paperPlaneSharp
  },*/
  {
    title: 'Integrations',
    url: '/Integrations',
    iosIcon: globeOutline,
    mdIcon: globeSharp
  },
  {
    title: 'Admin',
    url: '/Admin',
    iosIcon: settingsOutline,
    mdIcon: settingsSharp
  }/*,
  {
    title: 'Trash',
    url: '/page/Trash',
    iosIcon: trashOutline,
    mdIcon: trashSharp
  }*/
];

const labels = ["Archived"];

const Menu: React.FC = () => {
  
  const location = useLocation();

  const [isAdmin, setIsAdmin] = useState(false);
  const [busy, setBusy] = useState(true);

  useEffect(()=>{
    getCurrentUser().then( (user: any) => {
      if (user){
  
        readUser(user.uid).then((data: any) => {
          console.log(data);

          if (data && data.restricted && data.restricted.role == "admin"){
            setIsAdmin(true);
          }
          else {
            setIsAdmin(false);
          }
        });
        setBusy(false);
      }
      else {
        setBusy(false);
      }
    }).catch(e => {
      console.log(e);
      setBusy(false);
    });
  });

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
        <IonListHeader className="integration">
          <IonImg className="rr-logo-img" src="https://i.imgur.com/akmZhdi.png" />
        </IonListHeader>
          <IonNote>Dashboard</IonNote>
          { isAdmin ? 
            appPagesAdmin.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                    <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })
          : 
            appPages.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                    <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })
          }
        </IonList>
        <IonList id="labels-list">
          <IonItem button onClick={logoutUser} lines="none">
            <IonIcon color="danger" slot="start" icon={logOutOutline} />
            <IonLabel color="medium" >Log Out</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
