import React, { useEffect, useRef, useState } from "react";

import './CTM.css';

import { useLocation } from 'react-router';

import { IonAccordion, IonAccordionGroup, IonButton, IonCheckbox, IonContent, IonInput, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup, IonToast } from '@ionic/react';
import { useSelector } from "react-redux";
import { writeUserCTMKey, readAvailableReceivingNumbers, writeUserCTMAccounts, writeUserCTMQueues, clearUserCTMAccounts, clearUserCTMQueues, updateReceivingNumber } from "../../firebase";
import { alertCircleSharp, closeCircleOutline, shieldCheckmarkOutline } from "ionicons/icons";
import { update } from "firebase/database";
import {createCTMReceivingNumber, updateCTMQueuesWithReceivingNumber} from "../../ctm-api";

var CTM: React.FC = () => {

  const location = useLocation();

  const user_id = useSelector((state: any) => state.userData.uid);

  var redirectUri:any = new URLSearchParams(location.search).get("redirect_uri");
  const accessToken:any = new URLSearchParams(location.search).get("access_token");
  const refreshToken:any = new URLSearchParams(location.search).get("refresh_token"); 

  const [hasAPIKey, setHasAPIKey] = useState(false);

  const [showToast_validAPIKey, setShowToast_validAPIKey] = useState(false);
  const [showToast_invalidAPIKey, setShowToast_invalidAPIKey] = useState(false);

  const [apiKey, setAPIKey] = useState("");
  const [ctmAccounts, setCTMAccounts] = useState([]); 
  const [receivingNumbers, setReceivingNumbers] = useState([]); 

  const [selectedAccounts, setSelectedAccounts] = useState<any[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<any>('');

  const [selectedQueues, setSelectedQueues] = useState<any[]>([]);
  const [selectedReceivingNumber, setSelectedReceivingNumber] = useState<any>('');

  const [ctmQueues, setCTMQueues] = useState([]); 

  useEffect(() => { 
    readAvailableReceivingNumbers(user_id).then((receivingNumbers:any) => {
      setReceivingNumbers(receivingNumbers);
    }); 
  },[]); 

  useEffect(() => { 

    //go get a list of queues for each account and include them

    try {
      if (selectedAccount) {

        const queueProxyAPIEndpoint = 'https://us-central1-rollover-rep-307a0.cloudfunctions.net/getCTMQueues';
  
        //console.log("API Key:", apiKey); // TODO - remove debugging
        const response = fetch(queueProxyAPIEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            accounts: [selectedAccount],
            apiKey: apiKey
          })
        }).then((response) => {
          if (response.ok) {
            const data = response.json().then((data) => {
              // expected format {id: "id", name: "name", accountName: "accountName"} 
      
              console.log("Queues Retrieved Successfully:", data); // TODO - remove debugging
              if (data) {
                setCTMQueues(data); 
              }
  
            });
          } else {
            // Handle HTTP errors
            const errorData = response.json().then((errorData) => {
              console.error("API Error:", errorData); // TODO - remove debugging
              //setErrorMessage("An error occurred while verifying the API Key. Please try again.");
          });
          }
        });
      }
    } catch (error) {
      console.error("Fetch Error:", error); // TODO - remove debugging
      //setErrorMessage("An error occurred while verifying the API Key. Please try again.");
    }
    

  },[selectedAccount]); 

  return (
    <IonContent>
      <div className="integration-step-container"> 
        <h1 className="integration-step-title">CTM Integration Setup</h1>
        <div className="integration-step-save-option"> 
          <IonInput className="integration-step-input" placeholder="CTM Basic Authentication Token" value={apiKey} 
          onIonChange=
            {
              (event: any) => setAPIKey(event.target.value)
            }
          ></IonInput>

          <IonButton color="secondary" onClick={async ()=>{
            //check API key 

            try {
              const proxyAPIEndpoint = 'https://us-central1-rollover-rep-307a0.cloudfunctions.net/verifyCTMKey';
          
          
              //console.log("API Key:", apiKey); // TODO - remove debugging
              const response = await fetch(proxyAPIEndpoint, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  apiKey: apiKey,
                  userId: user_id
                })
              });
          
              if (response.ok) {
                const data = await response.json();
                console.log("API Key Verified Successfully:", data); // TODO - remove debugging
                writeUserCTMKey(user_id, apiKey);

                setHasAPIKey(true);
                setShowToast_validAPIKey(true);
                if (data.accounts) {
                  setCTMAccounts(data.accounts); 
                }
              } else {
                // Handle HTTP errors
                const errorData = await response.json();
                console.error("API Error:", errorData); // TODO - remove debugging
                setShowToast_invalidAPIKey(true);
                //setErrorMessage("An error occurred while verifying the API Key. Please try again.");
              }
            } catch (error) {
              console.error("Fetch Error:", error); // TODO - remove debugging
              setShowToast_invalidAPIKey(true);
              //setErrorMessage("An error occurred while verifying the API Key. Please try again.");
            }
          }}> Continue </IonButton>
        </div> 
        
        <div className="help-section">
          <span className="help-text">How do I find my key?</span>
          <div className="tooltip">
            <img 
              src="https://rolloverrep.com/wp-content/uploads/2025/01/Screenshot-2025-01-09-at-3.26.27 PM.png" 
              alt="How to find API key" 
            />
          </div>
        </div>

        {
          hasAPIKey ?
            <IonAccordionGroup className="integration-step-accordion-group">
              <IonAccordion value="accounts" className="integration-step-accordion" >
                <IonItem slot="header" className="integration-step-accordion-header">
                  <IonLabel className="integration-step-accordion-label">Select Account</IonLabel>
                </IonItem>
                <IonList slot="content"> 
                  <IonRadioGroup value={selectedAccount.id}>
                      {
                        ctmAccounts.map((account: any, index: number) => {
                          return (
                            <IonItem key={index} onClick={(event:any)=>{
                                setSelectedAccount({id: account.id, name: account.name});
                              }
                            }>
                              <IonLabel>{account.name}</IonLabel>
                              <IonRadio slot="end" value={account.id} color="secondary"></IonRadio>
                            </IonItem>
                          )
                        })
                      }
                  </IonRadioGroup>
                </IonList>


                {/*
                  ctmAccounts.map((account: any, index: number) => {
                    return (
                      <IonItem slot="content" key={index}>
                        <IonCheckbox slot="start" color="secondary"  onIonChange={(event:any)=>{

                          if (event.detail.checked){
                            setSelectedAccounts([...selectedAccounts, {id: account.id, name: account.name}]);
                          } else {
                            setSelectedAccounts(selectedAccounts.filter((selectedAccount) => selectedAccount.id !== account.id));
                          }

                        }}></IonCheckbox>
                        <IonLabel>{account.name}</IonLabel>
                      </IonItem>
                    )
                  })
               */} 

              </IonAccordion>
              {
                ctmQueues.length > 0 ? 
                <IonAccordion value="queues" className="integration-step-accordion">
                  <IonItem slot="header" className="integration-step-accordion-header">
                    <IonLabel className="integration-step-accordion-label">Select Queues</IonLabel>
                  </IonItem>
                  {
                    ctmQueues.map((queue: any, index: number) => {
                      return (
                        <IonItem slot="content" key={index}>
                          <IonCheckbox slot="start" color="secondary" onIonChange={(event:any)=>{

                            if (event.detail.checked){
                              setSelectedQueues([...selectedQueues, {id: queue.id, name: queue.name, accountName: queue.accountName}]);
                            } else {
                              setSelectedQueues(selectedQueues.filter((selectedQueue) => selectedQueue.id !== queue.id));
                            }

                          }}></IonCheckbox>
                          <IonLabel className="queue-label">
                            <div className="queue-label-header"> 
                              {queue.name}
                            </div> 
                            <div>
                              <span className="queue-label-subtitle">Account: </span>
                              <span>{queue.accountName}</span>
                            </div>
                          </IonLabel>
                        </IonItem>
                      )
                    })
                  }
                </IonAccordion>
                : 
                  <> </>
              } 
              {
                selectedQueues.length > 0 ? 
                  <IonAccordion value="receiving-numbers" className="integration-step-accordion">
                    <IonItem slot="header" className="integration-step-accordion-header">
                      <IonLabel className="integration-step-accordion-label">Select Your Receiving Number</IonLabel>
                    </IonItem>
                    <IonList slot="content">
                      <IonRadioGroup value={selectedReceivingNumber}>
                        {
                          receivingNumbers.map((receivingNumber: any, index: number) => {
                            return (
                              <IonItem key={index} onClick={(event:any)=>{
                                  setSelectedReceivingNumber(receivingNumber);
                                  console.log("Selected Receiving Number:", receivingNumber);
                                }
                              }>
                                <IonLabel>{receivingNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}</IonLabel>
                                <IonRadio slot="end" value={receivingNumber} color="secondary"></IonRadio>
                              </IonItem>
                            )
                          })
                        }
                      </IonRadioGroup>
                    </IonList>
                  </IonAccordion>
                :
                <> </>
              }
              {
                selectedReceivingNumber && selectedQueues.length > 0 ? 
                <IonAccordion value="save" className="integration-step-accordion">
                  <IonItem slot="header" className="integration-step-accordion-header">
                    <IonLabel className="integration-step-accordion-label">Finish</IonLabel>
                  </IonItem>
                  <IonItem slot="content">
                    <IonCheckbox slot="start" color="secondary"></IonCheckbox>
                    <IonLabel style={{ whiteSpace: 'normal' }}>
                      I acknowledge that my selected queues ring for 20s or less before transferring to Rollover Rep.
                    </IonLabel>
                  </IonItem>
                  <IonItem slot="content">
                    <IonCheckbox slot="start" color="secondary"></IonCheckbox>
                    <IonLabel style={{ whiteSpace: 'normal' }}>
                      I authorize and understand that Rollover Rep will update my selected queues to transfer missed calls to Rollover Rep staff. These phone calls will be answered on behalf of my facility, as brand-aware, and with the expectation that my team will follow up with a call back. 
                    </IonLabel>
                  </IonItem>
                  {/* TODO - Fix the fact that clicking anywhere on this item does not select / deselect the checbox like it does on the other checkboxes in this list*/}
                  <IonItem slot="content">
                    <IonCheckbox slot="start" color="secondary"></IonCheckbox>
                    <IonLabel style={{ whiteSpace: 'normal' }}>
                      I have read and agreed to the Rollover Rep Master Service Agreement and <a href="https://rolloverrep.com/privacy/"> Privacy Policy </a>.
                    </IonLabel>
                  </IonItem>
                  <IonItem slot="content">
                    {
                      // TODO - don't allow the user to finish until they've checked all the boxes. Store that they have checked these boxes somewhere in the db 
                    }
                    <IonButton className="integration-step-save-button" color="secondary" onClick={async ()=>{
                      console.log("Selected Queues:", selectedQueues); 
                      console.log("Selected Receiving Number:", selectedReceivingNumber); 

                      //TODO - send selected queues and receiving number to the backend
                      await clearUserCTMAccounts(user_id);
                      await writeUserCTMAccounts(user_id, [selectedAccount]);

                      await clearUserCTMQueues(user_id);
                      await writeUserCTMQueues(user_id, selectedQueues);

                      await updateReceivingNumber(selectedReceivingNumber, true, user_id);

                      //TODO - enable this to hit the CTM API. 
                      var ctmReceivingNumberResponse:any = await createCTMReceivingNumber(selectedAccount.id, apiKey, selectedReceivingNumber); 
                      var newlyCreatedReceivingNumber:any = ctmReceivingNumberResponse.receiving_number; 
                      var filterId = newlyCreatedReceivingNumber.filter_id; 

                      var queueIds = selectedQueues.map((queue:any) => queue.id);

                      console.log("Queue IDs:", queueIds);

                      await updateCTMQueuesWithReceivingNumber(selectedAccount.id, apiKey, queueIds, selectedReceivingNumber, filterId);
                      
                      //TODO - Show success toast, set timeout, show loader maybe? then redirect 

                      if (redirectUri.includes("?")){
                        redirectUri = redirectUri + "&access_token=" + accessToken+"&refresh_token=" + refreshToken;
                      }
                      else {
                        redirectUri = redirectUri + "?access_token=" + accessToken+"&refresh_token=" + refreshToken;
                      }

                      if (redirectUri){ 
                        window.location.href = redirectUri;
                      }

                      //console.log(redirectUri); //TODO - remove debugging

                    }}> Save & Finish </IonButton>
                  </IonItem>
                </IonAccordion>
                :
                <> </>
              }
            </IonAccordionGroup>
            : 
            <div></div>
        }
      </div>
      <IonToast
        isOpen={showToast_validAPIKey}
        onDidDismiss={() => setShowToast_validAPIKey(false)}
        message="API Key Validated"
        duration={2000}
        color="success"
        icon={shieldCheckmarkOutline}
      />

      <IonToast
        isOpen={showToast_invalidAPIKey}
        onDidDismiss={() => setShowToast_invalidAPIKey(false)}
        message="API Key Invalid"
        duration={2000}
        color="danger"
        icon={closeCircleOutline}
      />
    </IonContent>
  );
}

export default CTM;
