/*import { initializeApp } from 'firebase/app';

import {
  getAuth,
  onAuthStateChanged,
  getRedirectResult,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth';

import {
  getDatabase,
  ref,
  set,
  update,
  onValue,
  query,
  orderByChild,
  equalTo,
  get,
  remove
} from "firebase/database";

import { getAnalytics } from "firebase/analytics";
import { compass } from 'ionicons/icons';
*/


/* const config = {
  apiKey: "AIzaSyBmpBdO2Oy0GMx8Ya5MqcPBEP_pOQ_6628",
  authDomain: "rollover-rep-307a0.firebaseapp.com",
  databaseURL: "https://rollover-rep-307a0-default-rtdb.firebaseio.com",
  projectId: "rollover-rep-307a0",
  storageBucket: "rollover-rep-307a0.appspot.com",
  messagingSenderId: "37686270375",
  appId: "1:37686270375:web:554b05b942f129701c3e82",
  measurementId: "G-D465H9SMSS"
}; */ 

/* const app = initializeApp(config);
const database = getDatabase(app);
const auth = getAuth();
const analytics = getAnalytics(app); */ 

//TODO - Review
//var user = auth.currentUser;

export function createCTMReceivingNumber(accountId: any, apiKey: string, number: string) {

  return new Promise(async function (resolve, reject) { 

    console.log("Calling Cloud Function with..."); 
    console.log("Creating Receiving Number: ", number); // TODO - remove debugging
    console.log("Account ID: ", accountId); // TODO - remove debugging
    console.log("API Key: ", apiKey); // TODO - remove debugging")

    try {
        const receivingNumberProxyAPIEndpoint = 'https://us-central1-rollover-rep-307a0.cloudfunctions.net/createCTMReceivingNumber';

        const response = fetch(receivingNumberProxyAPIEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            accountId: accountId,
            apiKey: apiKey,
            number: number
          })
        }).then((response) => {
          if (response.ok) {
            const data = response.json().then((data) => {
              console.log("Receiving Number Created Successfully:", data); // TODO - remove debugging
              resolve(data); 
            });
          } else {
            // Handle HTTP errors
            const errorData = response.json().then((errorData) => {
              console.error("API Error:", errorData); // TODO - remove debugging
              reject(errorData); 
          });
          }
        });
      
    } catch (error) {
      console.error("Fetch Error:", error); // TODO - remove debugging
      reject(error); 
    }

}); 

}

export function updateCTMQueuesWithReceivingNumber(accountId: any, apiKey: string, queueIds: any[], receivingNumber: string, filterId: string) {
  return new Promise(async function (resolve, reject) { 

      try {
          const queuesProxyAPIEndpoint = 'https://us-central1-rollover-rep-307a0.cloudfunctions.net/updateCTMQueuesWithReceivingNumber';

          const requestBody = {
            accountId: accountId,
            apiKey: apiKey,
            number: receivingNumber,
            filterId: filterId,
            queueIds: queueIds 
          }; 

          console.log(requestBody); 
          console.log(JSON.stringify(requestBody)); 

          const response = fetch(queuesProxyAPIEndpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
          }).then((response) => {
            if (response.ok) {
              const data = response.json().then((data) => {
                console.log("Queues Updated Successfully:", data); // TODO - remove debugging
                resolve(data); 
              });
            } else {
              // Handle HTTP errors
              const errorData = response.json().then((errorData) => {
                console.error("API Error:", errorData); // TODO - remove debugging
                reject(errorData); 
            });
            }
          });
        
      } catch (error) {
        console.error("Fetch Error:", error); // TODO - remove debugging
        reject(error); 
      }

  }); 
}
