import React, { useState, useEffect } from 'react';
import { IonBreadcrumbs, IonBreadcrumb, IonList, IonItem, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonButton, IonChip, IonPopover, IonInput, IonLabel, IonItemDivider, IonHeader, IonToolbar, IonTitle, IonContent, IonIcon } from '@ionic/react';
import './AdminReceivingNumbers.css';
import { readAllReceivingNumbers, updateReceivingNumber, getUserByEmail, deleteReceivingNumber } from "../../firebase";
import { addCircleOutline } from 'ionicons/icons';

const AdminReceivingNumbers: React.FC = () => {
  const [receivingNumbers, setReceivingNumbers] = useState<any[]>([]);
  const [filter, setFilter] = useState<string>('all');

  const [selectedNumber, setSelectedNumber] = useState<any>(null);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [popoverEvent, setPopoverEvent] = useState<MouseEvent | undefined>(undefined);

  const [newNumber, setNewNumber] = useState<any>(null);
  const [showAddNumberPopover, setShowAddNumberPopover] = useState<boolean>(false);
  const [addNumberPopoverEvent, setAddNumberPopoverEvent] = useState<MouseEvent | undefined>(undefined);
  
  const [numberToDelete, setNumberToDelete] = useState<any>(null);
  const [numberToDeleteOwner, setNumberToDeleteOwner] = useState<any>(null); 
  const [showDeleteNumberPopover, setShowDeleteNumberPopover] = useState<boolean>(false);
  const [deleteNumberPopoverEvent, setDeleteNumberPopoverEvent] = useState<MouseEvent | undefined>(undefined);

  const [numberToRelease, setNumberToRelease] = useState<any>(null);


  useEffect(() => {
    readAllReceivingNumbers().then((receivingNumbers: any) => {
      setReceivingNumbers(receivingNumbers);  
      console.log("Received Receiving Numbers: "); 
      console.log(receivingNumbers); 
    });
  }, [showPopover, selectedNumber, newNumber, showAddNumberPopover, numberToDelete, numberToRelease]);



  const filteredNumbers = receivingNumbers.filter(number => {
    if (filter === 'claimed') {
      return number.ownerId;
    } else if (filter === 'unclaimed') {
      return !number.ownerId;
    } else {
      return true;
    }
  });

  const handleEditClick = (event: MouseEvent, number: any) => {
    console.log("Edit Clicked: "+number);
    setSelectedNumber(number);
    setPopoverEvent(event);
    setShowPopover(true);
  };

  const handleSave = (event:any) => {
    getUserByEmail(selectedNumber.email).then((user: any) => { 
      console.log(user); 
      const userId = Object.keys(user)[0];
      updateReceivingNumber(selectedNumber.number, true, userId); 
      setShowPopover(false);
    });
  }; 

  const handleNewNumberSave = (event:any) => {
    updateReceivingNumber(newNumber, false); 
    setNewNumber(""); 
    setShowAddNumberPopover(false);
  };

  const deleteNumber = (event:any) => {
    if (numberToDeleteOwner){
      deleteReceivingNumber(numberToDelete, numberToDeleteOwner); 
    }
    else {
      deleteReceivingNumber(numberToDelete); 
    }
    setNumberToDelete(""); 
    setNumberToDeleteOwner(""); 
    setShowDeleteNumberPopover(false);
  };

  const handleDeleteClick = (event: MouseEvent, number: any) => {
    console.log("Delete Clicked: "+number.number); //todo - remove debugging
    console.log(number); 
    setNumberToDelete(number.number);
    if (number.ownerId){
      setNumberToDeleteOwner(number.ownerId);
    }
    setDeleteNumberPopoverEvent(event);
    setShowDeleteNumberPopover(true);
  };

  const releaseNumber = (event:any, number:any) => {
    setNumberToRelease(number.number); 
    updateReceivingNumber(number.number, false, number.ownerId); 
    setNumberToRelease(""); 
  };

  return (
    <>
      <IonBreadcrumbs className="breadcrumb">
        <IonBreadcrumb href="/Admin">Admin Panel</IonBreadcrumb>
        <IonBreadcrumb href="/Admin">Receiving Numbers</IonBreadcrumb>
      </IonBreadcrumbs>

      <div className="receiving-numbers-top-bar"> 
        <div className="receiving-numbers-filter"> 
          <IonChip onClick={e => setFilter("all")} color={filter === "all" ? "primary" : "default"}>All</IonChip>
          <IonChip onClick={e => setFilter("claimed")} color={filter === "claimed" ? "primary" : "default"}>Claimed</IonChip>
          <IonChip onClick={e => setFilter("unclaimed")} color={filter === "unclaimed" ? "primary" : "default"}>Unclaimed</IonChip>
        </div>
        <div> 
          <IonButton className="receiving-numbers-add-button" fill="clear" onClick={(event:any) => {setAddNumberPopoverEvent(event); setShowAddNumberPopover(true);}}>
            <IonIcon slot="start" icon={addCircleOutline}></IonIcon>
            Add New
          </IonButton>
        </div> 
      </div> 

      <IonList className="receiving-number-list">
        {filteredNumbers.map((number, index) => (
          <IonItem key={index}>
            <IonCard className="receiving-number-card">
              <IonCardHeader>
                <IonCardTitle>{number.number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}</IonCardTitle>
                <IonCardSubtitle>{number.email ? `Owner: ${number.email}` : '✅ Unclaimed'}</IonCardSubtitle>
              </IonCardHeader>

              <div>
                <IonButton fill="clear" onClick={(event:any) => handleEditClick(event, number)}>Edit</IonButton>
                {number.ownerId ? (
                    <IonButton fill="clear" onClick={(event:any) => releaseNumber(event, number)}>Release</IonButton>
                ) : (<> </>
                )}
                <IonButton fill="clear" onClick={(event:any) => {handleDeleteClick(event, number);}}>Delete</IonButton>

              </div>
            </IonCard>
          </IonItem>
        ))}
      </IonList>

      <IonPopover
        isOpen={showPopover}
        event={popoverEvent}
        onDidDismiss={() => setShowPopover(false)}
        className="receiving-number-popover"
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Edit Number</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem>
            <IonLabel position="stacked">Number</IonLabel>
            <IonInput value={selectedNumber?.number} onIonChange={e => setSelectedNumber({ ...selectedNumber, number: e.detail.value! })} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput value={selectedNumber?.email} onIonChange={e => setSelectedNumber({ ...selectedNumber, email: e.detail.value! })} />
          </IonItem>
          <IonButton expand="block" onClick={handleSave}>Save</IonButton>
          <IonButton expand="block" color="light" onClick={() => setShowPopover(false)}>Cancel</IonButton>
        </IonContent>
      </IonPopover>


      <IonPopover
        isOpen={showAddNumberPopover}
        event={addNumberPopoverEvent}
        onDidDismiss={() => setShowAddNumberPopover(false)}
        className="receiving-number-popover"
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Add New</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem>
            <IonLabel position="stacked">Number</IonLabel>
            <IonInput value={newNumber} onIonChange={e => setNewNumber(e.detail.value)} />
          </IonItem>
          <IonButton expand="block" onClick={handleNewNumberSave}>Save</IonButton>
          <IonButton expand="block" color="light" onClick={() => setShowAddNumberPopover(false)}>Cancel</IonButton>
        </IonContent>
      </IonPopover>

      <IonPopover
        isOpen={showDeleteNumberPopover}
        event={deleteNumberPopoverEvent}
        onDidDismiss={() => setShowDeleteNumberPopover(false)}
        className="receiving-number-popover"
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Are you sure?</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonButton expand="block" color="danger" onClick={deleteNumber}>Confirm</IonButton>
          <IonButton expand="block" color="light" onClick={() => setShowDeleteNumberPopover(false)}>Cancel</IonButton>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default AdminReceivingNumbers;
