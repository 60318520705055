import React, { useEffect, useState } from "react";

import { 
  IonButtons, 
  IonContent, 
  IonHeader, 
  IonMenuButton, 
  IonPage, 
  IonTitle, 
  IonToolbar,
  IonCard, 
  IonCardHeader, 
  IonCardTitle, 
  IonImg, 
  IonCardContent, 
  IonButton, 
  IonIcon, 
  IonItem, 
  IonLabel, 
  IonCheckbox,
  IonList,
  IonCardSubtitle
} from '@ionic/react';

import './Admin.css';

import { 
  checkmarkCircleOutline, 
  checkmarkCircleSharp, 
  archiveOutline, 
  archiveSharp, 
  bookmarkOutline,
  heartOutline, 
  heartSharp, 
  mailOutline, 
  mailSharp, 
  paperPlaneOutline, 
  paperPlaneSharp, 
  trashOutline, 
  trashSharp, 
  warningOutline, 
  warningSharp, 
  homeOutline, 
  homeSharp, 
  globeOutline, 
  globeSharp, 
  settingsOutline, 
  settingsSharp
 } from 'ionicons/icons';

import { useLocation } from 'react-router-dom';

var Admin: React.FC = () => {

  const location = useLocation();

  return (
    <IonList className="admin-list">
      <IonItem>
        <IonButton className="admin-list-button" href="/Admin/Receiving-Numbers">Receiving Numbers</IonButton>
      </IonItem>
    </IonList>
  );
}

export default Admin;
