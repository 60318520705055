import { initializeApp } from 'firebase/app';

import {
  getAuth,
  onAuthStateChanged,
  getRedirectResult,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth';

import {
  getDatabase,
  ref,
  set,
  update,
  onValue,
  query,
  orderByChild,
  equalTo,
  get,
  remove
} from "firebase/database";

import { getAnalytics } from "firebase/analytics";
import { compass } from 'ionicons/icons';


const config = {
  apiKey: "AIzaSyBmpBdO2Oy0GMx8Ya5MqcPBEP_pOQ_6628",
  authDomain: "rollover-rep-307a0.firebaseapp.com",
  databaseURL: "https://rollover-rep-307a0-default-rtdb.firebaseio.com",
  projectId: "rollover-rep-307a0",
  storageBucket: "rollover-rep-307a0.appspot.com",
  messagingSenderId: "37686270375",
  appId: "1:37686270375:web:554b05b942f129701c3e82",
  measurementId: "G-D465H9SMSS"
};

const app = initializeApp(config);
const database = getDatabase(app);
const auth = getAuth();
const analytics = getAnalytics(app);

//TODO - Review
var user = auth.currentUser;

export function clearUserCTMAccounts(userId: string) {
  return new Promise(function (resolve, reject) {
    const updates: any = {};

    updates['/users/' + userId + '/private/ctm/accounts'] = {};

    var result:any;

    update(ref(database), updates).then((res) => {
      console.log("Accounts Cleared"); // TODO - remove debugging
      result = res;
      resolve(res); 
    })
    .catch((error) => {
      console.log(error);
      result = "error";
      reject(error); 
    });
  }); 
}

export function writeUserCTMAccounts(userId: string, accounts: any[]) {
  return new Promise(function (resolve, reject) {
    const updates: any = {};

    for (var i=0; i<accounts.length; i++){
      updates['/users/' + userId + '/private/ctm/accounts/' + accounts[i].id] = true;
    }

    var result:any;

    update(ref(database), updates).then((res) => {
      console.log("Accounts Saved"); // TODO - remove debugging
      result = res;
      resolve(res); 
    })
    .catch((error) => {
      console.log(error);
      result = "error";
      reject(error); 
    });
  }); 
}


export function clearUserCTMQueues(userId: string) {
  return new Promise(function (resolve, reject) {
    const updates: any = {};

    updates['/users/' + userId + '/private/ctm/queues'] = {};

    var result:any;

    update(ref(database), updates).then((res) => {
      console.log("Queues Cleared"); // TODO - remove debugging
      result = res;
      resolve(res); 
    })
    .catch((error) => {
      console.log(error);
      result = "error";
      reject(error); 
    });
  });
}


export function writeUserCTMQueues(userId: string, queues: any[]) {
  return new Promise(function (resolve, reject) {
    const updates: any = {};

    for (var i=0; i<queues.length; i++){
      updates['/users/' + userId + '/private/ctm/queues/' + queues[i].id] = true;
    }

    var result:any;

    update(ref(database), updates).then((res) => {
      console.log("Queues Saved"); // TODO - remove debugging
      result = res;
      resolve(res); 
    })
    .catch((error) => {
      console.log(error);
      result = "error";
      reject(error); 
    });
  }); 
}


export function readAvailableReceivingNumbers(userId: string) {
  return new Promise(function (resolve, reject) {
    const numberRef = ref(database, 'receiving_numbers');

    var rn: any;
    onValue(numberRef, async (snapshot) => {
      
      const data = snapshot.val();
      if (data){
        rn = data;
        var numbers = []; 
        
        for ( var n in rn) {
          if (!rn[n]){
            numbers.push(n);
          }
          else if (rn[n] == userId) {
            numbers.push(n);
          }
        }
        resolve(numbers);
      }
      else {
        rn = "";
        reject(rn);
      }
    });
  });
}


export function writeUserCTMKey(userId: string, key: string) {

  const updates: any = {};
  updates['/users/' + userId + '/private/ctm/key'] = key;

  var result:any;

  update(ref(database), updates).then((res) => {
    console.log("Key Saved"); // TODO - remove debugging
    result = res;
  })
  .catch((error) => {
    console.log(error);
    result = "error";
  });

  console.log(result);
  return result;
}


export function generateSecureToken(length: number = 32): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let token = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    token += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return token;
}

export function validateRefreshToken(userId: string, refreshToken: string) {
  return new Promise( function (resolve, reject) {

    readUser(userId).then( async (user: any) => {

      if (user.private && user.private.refresh_token && user.private.refresh_token === refreshToken) {
        var accessToken = await user.getIdToken();
        resolve(accessToken);
      } else {
        resolve(false);
      }

    }); 
  }); 
}

export function createRefreshToken(userId: string) {
  return new Promise(function (resolve, reject) {

    const refreshToken: string = generateSecureToken();

    const updates: any = {};
    
    updates['/users/' + userId + '/private/refresh_token'] = refreshToken;

    var result:any;
  
    update(ref(database), updates).then((res) => {
      console.log("Token Saved"); // TODO - remove debugging
      result = res;
      resolve(refreshToken); 
    })
    .catch((error) => {
      console.log(error);
      result = "error";
      reject(result); 
    });
  }); 
}


export function deleteReceivingNumber(receivingNumber: string, ownerId?: string) {
  return new Promise((resolve, reject) => {
    const deleteRef = ref(database, 'receiving_numbers/' + receivingNumber);

    remove(deleteRef).then(() => {
      console.log(`Receiving Number Deleted: ${receivingNumber}`); // TODO - remove debugging

      if ( ownerId ) {
        const userRef = ref(database, 'users/' + ownerId + '/private/ctm/receiving_numbers' + receivingNumber);
        remove(userRef).then(() => {
          console.log(`Receiving Number Deleted from User: ${receivingNumber}`); // TODO - remove debugging
          resolve(`Receiving Number Deleted: ${receivingNumber}`);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
      }
      else {
        resolve(`Receiving Number Deleted: ${receivingNumber}`);
      }
    })
    .catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function getUserByEmail(email: string) {
  return new Promise((resolve, reject) => {

    const usersRef = ref(database, 'users');

    const emailQuery = query(usersRef, orderByChild('private/email'), equalTo(email));

    get(emailQuery).then((snapshot) => {
      if (snapshot.exists()) {
        const userData = snapshot.val();
        resolve(userData);
      } else {
        reject('User not found');
      }
    }).catch((error) => {
      reject(error);
    });
  });
};

export function readAllReceivingNumbers() {
  return new Promise(function (resolve, reject) {
    const numberRef = ref(database, 'receiving_numbers');

    var rn: any;
    onValue(numberRef, async (snapshot) => {
      
      const data = snapshot.val();
      if (data){
        rn = data;
        var numbers = []; 
        var obj:any = {};

        for ( var n in rn) {
          obj = {}; 

          if (rn[n]){
            var u:any = await readUser(rn[n]);
            console.log("User...");
            console.log(u); 
            if (u.private && u.private.email) {
              obj.email = u.private.email; 
            } 

            obj.ownerId = rn[n]; 
          }
          obj.number = n; 

          numbers.push(obj);
        }
        resolve(numbers);
      }
      else {
        rn = "";
        reject(rn);
      }
    });
  });
}

export function readUser(userId: string) {
  return new Promise(function (resolve, reject) {
    const userRef = ref(database, 'users/' + userId);

    var u: any;

    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      console.log(data); 
      if (data){
        u = data;
        console.log("User..."); //TODO - remove debugging
        console.log(u); //TODO - remove debugging
        resolve(u);
      }
      else {
        u = "";
        reject(u);
      }
    });
  });
}

export function updateReceivingNumber(receivingNumber: string, isClaiming: boolean, ownerId?: string) {

  return new Promise(function (resolve, reject) {

    // If there is an ownerId, claim that number for that owner. If there is no ownerId, unclaim that number. 

    const updates: any = {};
    var message = ""; 

    if (isClaiming){ 
      updates['/receiving_numbers/' + receivingNumber] = ownerId;
      updates['/users/' + ownerId + '/private/ctm/receiving_numbers/'+receivingNumber] = true;
      message = `Receiving Number Claimed! Number: ${receivingNumber}, Owner: ${ownerId}`; 
    }
    else if (ownerId) {
      updates['/receiving_numbers/' + receivingNumber] = "";
      updates['/users/' + ownerId + '/private/ctm/receiving_numbers/'+receivingNumber] = false;
      message = `Receiving Number Unclaimed! Number: ${receivingNumber}, Former Owner: ${ownerId}`; 
    }
    else {
      updates['/receiving_numbers/' + receivingNumber] = "";
      message = `New Receiving Number! Number: ${receivingNumber}`; 

    }

    var result:any;

    update(ref(database), updates).then((res) => {
      console.log(message); // TODO - remove debugging
      result = res;
      resolve(res); 
    })
    .catch((error) => {
      console.log(error);
      result = "error";
      reject(error); 
    });
  }); 
}

export function readReceivingNumber(receivingNumber: string) {
  return new Promise(function (resolve, reject) {
    const numberRef = ref(database, 'receiving_numbers/' + receivingNumber);

    var rn: any;

    onValue(numberRef, (snapshot) => {
      const data = snapshot.val();
      if (data){
        rn = data;
        resolve(rn);
      }
      else {
        rn = "";
        reject(rn);
      }
    });
  });
}

export function adminCreateReceivingNumber(receivingNumber: string) {

  const updates: any = {};
  updates['/receiving_numbers/' + receivingNumber ] = "";

  var result:any;

  update(ref(database), updates).then((res) => {
    console.log("Receiving Number Created"); // TODO - remove debugging
    result = res;
  })
  .catch((error) => {
    console.log(error);
    result = "error";
  });

  console.log(result);
  return result;
}

export function readUserSalesforceCode(userId: string) {
  return new Promise(function (resolve, reject) {
    const userRef = ref(database, 'users/' + userId);

    var code: any;

    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      if (data && data.salesforce_code){
        code = data.salesforce_code;
        resolve(code);
      }
      else {
        code = "";
        reject(code);
      }
    });
  });
}

export function writeUserSalesforceCode(userId: string, code: string) {

  const updates: any = {};
  updates['/users/' + userId + '/salesforce_code'] = code;

  var result:any;

  update(ref(database), updates).then((res) => {
    console.log("Code Saved"); // TODO - remove debugging
    result = res;
  })
  .catch((error) => {
    console.log(error);
    result = "error";
  });

  console.log(result);
  return result;
}

export function writeBasicUserInfo(userId: string, email: string) {

  return new Promise(function (resolve, reject) {
    const updates: any = {};
    updates['/users/' + userId + '/private/email'] = email;
  
    var result:any;
  
    update(ref(database), updates).then((res) => {
      console.log("Email Saved"); // TODO - remove debugging
      result = res;
      resolve(result); 
    })
    .catch((error) => {
      console.log(error);
      result = "error";
      reject(result); 
    });
  }); 
}

export function getCurrentUser(){
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (u: any) => {
      if (u) {
        //console.log("User Logged In from firebase.tsx: " + u.uid); //debugging TODO - remove
        resolve(u);
      }
      else {
        reject(null);
      }
    });
    unsubscribe();
  });
}

export async function signInUserWithEmail(email: string, password: string){
  signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
    const user = userCredential.user;
    console.log(user);
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage);
  });
}

export function logoutUser(){
  return signOut(auth).then(() => {
    window.history.replaceState({}, '', '/Login');
    window.location.href = "/Login";
  }).catch((error) => {
    // An error happened.
    console.log(error);
  });
}

export {app, auth, user, database};
